import '../../../../App.css';
import '../../Bank/bankstatement.css';
import { v4 as uuidv4 } from 'uuid';
import { ReactDialogBox } from 'react-js-dialog-box'
import 'react-js-dialog-box/dist/index.css'
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CreditReport from '../../credit-report/credit-report.js';
import BankStatmentForm from '../../../boost-score/bank-statement/bank-statement.js';
import PaginationComponent from '../../../Component/pagination/pagination-component.js';
import CreateCustomer from '../../customer/create-customer.js';
import BankStatmentMultiple from '../../../boost-score/bank-statement/bank-statement-multiple.js';
import { getUserProdList } from '../../../../redux/actions/prod_action.js';
import ErrorMessage from '../../error-message/error-message.js';
import ChatIcon from '../../../Boost-AI/Chat-Icon/chat-icon.js';
import { reportDownload, downloadExcelReport, downloadExcelAnalysisReport, getClientConfiguration } from '../../../../redux/actions/prod-b2b_action.js';
import moment from 'moment/moment';
import Loader from '../../../loader/loader.js';
import './my-customer.css'
import AsyncStorage from '@react-native-async-storage/async-storage';
import config from '../../../../config.json';
import { getCommonProdClientTokenB2B } from '../../../Component/GenetateProdClientTokenB2B.js';
import getSDKError from '../../../../Common/getSDKError.js';
import DownloadReportBtn from '../../../Component/download-report-btn/download-report-btn.js';
import DataTable from 'react-data-table-component';
import SuccessFailDialog from '../../../Component/dialogs/success-fail-dialog.js';
import Modal from 'react-responsive-modal';
import GSTUploadDocuments from '../../gst-report/gst-uploadstatement.js';
import { gstExcelDownloadProdApi } from '../../../../redux/actions/prod-b2b_action.js';
import SlidingPanel from '../silding-component/sliding-panel.js';
import TabComponent from '../../../Component/tab-component/tab-component.js';
import SubTabComponent from '../sub-tab-component/sub-tab-component.js';
import { getCustomerList } from '../../../../redux/actions/dashboard_action.js';
import FilterComponent from '../searchbar/filter-component.js';
const AddBank = require("../../../../images/assets/AddBank.png");
const GetInsights = require("../../../../images/assets/Getinsights.png");
const Finances = require("../../../../images/assets/Finances.png");
const UserListIC = require("../../../../images/assets/user-list-ic.png");
const DownArrowIC = require("../../../../images/assets/down_arrow.png");
const UploadIC = require("../../../../images/assets/upload_ic.png");
const DownloadIC = require("../../../../images/assets/dwnl_ic.png");



function MyCustomerList(props) {
    const [isOpenDialog, setIsOpenDialog] = useState(false);   // Manage Credit Report Component
    const [isOpenTestUserDialog, setIsOpenTestUserDialog] = useState(false) // Manage Test User Report Component
    const [isOpenBankStmDialog, setIsOpenBankStmDialog] = useState(false) // Manage Bank Statement Component
    const [dialogTestUserTitle, setDialogTestUserTitle] = useState('Enter Customer Name')
    const [dialogTitle, setDialogTitle] = useState('Upload Credit Report')
    const [dialogBankStmTitle, setDialogBankStmTitle] = useState('Upload Bank Statement')
    const [userListData, setUserListData] = useState()
    const [businessId, setBusinessId] = useState()
    const [isShowSkipBtn, setIsShowSkipBtn] = useState(true)
    const [message, setMessage] = useState();
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [panelData, setPanelData] = useState({
        'businessUserId': null, "isOpnePanel": false,
        "fullName": null, "date": null,
    });
    const [userListDataFilter, setUserListDataFilter] = useState()
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [shouldToggle, setShouldToggle] = useState(false);
    const [openMultipleScreen, setOpenMultipleScreen] = useState(false);
    const [openGSTDialog, setOpenGSTDialog] = useState(false);
    /// below var use for paginagtion stuff
    const [sizePerPage, setSizePerPage] = useState(5); /// initial default sizePerPage === 5
    const [totalRecordFromApi, setTotalRecordFromApi] = useState(0);
    const [totalPageFromApi, setTotalPageFromApi] = useState(0);
    const [currentRow, setCurrentRow] = useState(null);
    const [fiuMultiBankSPF, setFiuMultiBankSPF] = useState('');
    const [fiuPartialMsg, setFiuPartialMsg] = useState({ msg: '', uuid: '' });
    const [openResSPFDialog, setOpenStatmentResSPFDialog] = useState(false);
    let [clientAllConfig, setClientAllConfig] = useState({});

    const navigate = useNavigate();
    const { state } = useLocation();

    const [dialogHeight, setDialogHeight] = useState("auto");

    const { user } = useSelector((state) => {
        return state.authReducer;
    });

    useEffect(() => {
        if (!user?.access_token) {
            navigate("/");
        }


        /// initially 0 page no pass to API
        // getCommonProdClientTokenB2B(
        //     () => {
        //         getClientConfiguration().then((resp) => {
        //             if (resp?.allConfiguration && resp.statusCode === "SUCCESS") {
        //                 setClientAllConfig(resp?.allConfiguration);
        //             }
        //         }).catch((error) => {
        //         }).finally(() => {

        //             setTotalRecordFromApi(0)
        //             setTotalPageFromApi(0)
        //             /// initially 0 page no pass to API
        //             getCustomerListFun(0);

        //             const prefix = '#customerList';
        //             if (window.location.hash === prefix) {
        //                 setTimeout(() => {
        //                     scrollToUser();
        //                 }, 300)
        //             }

        //         });
        //     },
        //     (errorMessage) => {
        //     }
        // );

        setTotalRecordFromApi(0)
        setTotalPageFromApi(0)
        /// initially 0 page no pass to API
        getCustomerListFun(0);

        const prefix = '#customerList';
        if (window.location.hash === prefix) {
            setTimeout(() => {
                scrollToUser();
            }, 300)
        }
        var sdkError = getSDKError()
        setMessage(sdkError)

    }, []);

    const scrollToUser = () => {
        const websdkSection = document.getElementById('userslist');
        if (websdkSection) {
            websdkSection.scrollIntoView({
                top: websdkSection.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    // const handleClick = (e) => {
    //   const isDownloadBtnChild = e.target.closest('.download-btn-dropdown');
    //   const isDropdownToggleChild = e.target.closest('.dropdown-toggle-a');
    //   if (isDownloadBtnChild || isDropdownToggleChild) {
    //     setShouldToggle(false);
    //     return;
    //   }
    //   setShouldToggle(true);
    // }

    // For Test User
    const openTestUser = () => {
        generateClientTokenProdB2B(false);
    };

    const closeTestUserDialog = () => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        setDialogTestUserTitle("Enter Customer Name");
        setDialogHeight("auto");
        //props.scrollToTop()
    };

    // For Credit Report
    const openCreditReport = (businessid) => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(true);
        setBusinessId(businessid);
        setIsShowSkipBtn(false);
        setOpenGSTDialog(false);
    };

    const closeDialog = () => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        // setIsOpenBankStmDialog(false)
        setDialogTitle("Upload Credit Report");
        setDialogHeight("auto");
        setOpenGSTDialog(false);
        //props.scrollToTop()
    };

    // For Bank Statement
    const openBankStatement = (businessid) => {
        setDialogBankStmTitle("Upload Bank Statements");
        setIsOpenBankStmDialog(true);
        setBusinessId(businessid);
        setIsShowSkipBtn(false);
        setOpenGSTDialog(false);
    };

    const closeBankStmDialog = () => {
        setIsOpenBankStmDialog(false);
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        setDialogBankStmTitle("Upload Bank Statement");
        setDialogHeight("auto");
        setOpenGSTDialog(false);
        //props.scrollToTop()
    };

    const openMultipleBankStatement = () => {
        setIsOpenBankStmDialog(false);
        setDialogBankStmTitle("Upload Multiple Banks");
        setIsShowSkipBtn(true);
        setDialogHeight("550px");
        setOpenGSTDialog(false);
        setOpenMultipleScreen(true)
    };

    const closeMultiBankStmDialog = () => {
        setIsOpenBankStmDialog(false);
        setIsOpenTestUserDialog(false);
        setOpenMultipleScreen(false)
        setIsOpenDialog(false);
        setDialogHeight("auto");
        //props.scrollToTop()
    };

    const openStatmentResSPFDialog = () => {
        closeMultiBankStmDialog();
        setOpenStatmentResSPFDialog(true)
    }

    const closeStatmentResSPFDialog = () => {
        setOpenStatmentResSPFDialog(false)
        setTotalRecordFromApi(0)
        setTotalPageFromApi(0)
        /// initially 0 page no pass to API
        getCustomerListFun(0);

    }
    const openGSTUpload = (businessid) => {
        setIsOpenTestUserDialog(false);
        setIsOpenDialog(false);
        setBusinessId(businessid);
        setDialogTitle("Upload GST Documents");
        setOpenGSTDialog(true);
    };

    /// onChangePage using arrows for Tabel's paginations
    const handlePerRowsChange = async (pageNo, totalRecord) => {
        if (pageNo > 0) {
            pageNo = pageNo - 1;
        } else {
            pageNo = 0;
        }
        setLoader(true)
        getCustomerListFun(pageNo, true);
    };

    const getCustomerListFun = (page, fromPagination = false) => {

        getCustomerList(page, sizePerPage).then((resp) => {
            setLoader(false)
            setUserListData(resp?.scrubMetaDataEntityList)
            setUserListDataFilter(resp.scrubMetaDataEntityList)
            // console.log("resp.data.totalPage; " + resp?.totalPage + " R " + resp?.totalData)
            // below param use for Paginations   
            setTotalRecordFromApi(resp?.totalData);
            setTotalPageFromApi(resp?.totalPage);
            // console.log("CUST LIST  ", resp);

            /// HIDE ELEMENT Class which prevented download button hidden issue fixed
            setTimeout(() => {
                var element = document.querySelector('.fpBGKh');
                if (element) {
                    // Disable attributes
                    for (var i = 0; i < element.attributes.length; i++) {
                        element.removeAttribute(element.attributes[i].name);
                    }
                }
            }, 1000);

            if (!fromPagination) {
                const rootUat = document.querySelector('#root-uat');
                rootUat.scrollIntoView({
                    top: rootUat.offsetTop,
                    behavior: 'smooth'
                }, 500)
            } else {
                scrollToUser();
            }


        }).catch((error) => {

        })
    }

    const downloadReport = (businessId) => {

        setOpen(false);
        setLoader(true);
        const param = {
            businessUserId: businessId
        }
        const uuid = uuidv4();
        getCommonProdClientTokenB2B(
            () => {
                reportDownload(param, uuid)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
                        // Create a temporary <a> element to initiate the download
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'boostreport.pdf';
                        document.body.appendChild(a);
                        a.click();
                        // Cleanup the temporary URL and <a> element
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the PDF file:', error);
                    })

            },
            (errorMessage) => {
                setLoader(false);
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }


    const downloadExcel = (businessId) => {
        setOpen(false);
        setLoader(true);
        const param = {
            businessUserId: businessId
        }
        const uuid = uuidv4();
        getCommonProdClientTokenB2B(
            () => {
                downloadExcelReport(param, uuid)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'boost_transaction_report.xls';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the Excel file:', error);
                    })
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const downloadExcelAnalysis = (businessId, accountUID) => {
        setOpen(false);
        setLoader(true);
        const param = {
            businessUserId: businessId,
            accountUID: accountUID
        }
        const uuid = uuidv4();
        getCommonProdClientTokenB2B(
            () => {
                downloadExcelAnalysisReport(param, uuid)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'excel_analysis_report_powered_by_FinBit_io.xls';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the Excel file:', error);
                    })
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const DownloadGSTExcelReport = (businessId,) => {
        setOpen(false);
        setLoader(true);
        const param = {
            businessUserId: businessId,
        }
        const uuid = uuidv4();
        getCommonProdClientTokenB2B(
            () => {
                gstExcelDownloadProdApi(param, uuid)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response], { type: "application/excel" }));
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'gst_excel_analysis_report.xls';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                        console.error('Error downloading the Excel file:', error);
                    })
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const handleOpen = () => {
        setOpen(!open);
    };

    const dropdownStatus = open ? "show" : "";


    //// style for data tabels
    const customStyles = {
        rows: {
            style: {
                borderRadius: '8px',
                minHeight: '90px',
                margin: '10px 0px',
                border: '1px solid #CECECE',
                color: 'red', display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                paddingTop: '12px',
                paddingBottom: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                cursor: 'pointer',
                userSelection: 'none',
                alignItems: 'center'
            },
        },
        table: {
            style: {
                marginBottom: '10px'
            },
        },
    };

    //// cells for data tabels
    const InfoRow = ({ date, name }) => (
        <div className='d-flex align-items-start justify-content-center'>
            <img src={UserListIC} alt='UserListIC' className='user-ic' />
            <div className='ps-2'>
                <div className='align-items-center justify-content-center'>
                    <p className='bank-name-text p-0 m-0'>{name}</p>
                </div>
                <div className='align-items-center justify-content-center'>
                    <h1 className='reported-date mb-0 p-0  pt-1'>{date}</h1>
                </div>
            </div>
        </div>
    );
    const UserName = ({ row }) => (
        <InfoRow date={row.createdDate != null ? moment(row.userCreatedDate).format('DD-MM-YYYY h:mm A') : '-'}
            name={row.firstName + " " + row.lastName} />
    );
    const DownloadUploadButton = ({ row }) => (

        <div className=''>
            <div className='d-flex align-items-center'>
                {/* {clientAllConfig?.aiEnable && <div className='pe-4'>
                    <ChatIcon openFromProd={true} businessId={row.businessUserId} name={row.displayName} ></ChatIcon>
                </div>
                } */}
                {/* {(row != null && (row.creditDetails.length > 0 || row.bankAccountDetails.length > 0)) &&
                    <DownloadReportBtn
                        isShowSupportingDocs={clientAllConfig && clientAllConfig?.lendingCopilot}
                        btnLabel='Download Reports'
                        clickOnDownload={() => {


                            // console.log("row?.businessUserId " + row.businessUserId)
                            downloadReport(row.businessUserId);
                            // Event for download
                        }}
                        clickOnExcel={() => {
                            // console.log("row?.businessUserId " + row.businessUserId)
                            downloadExcel(row.businessUserId);
                            // Event for download excel
                        }}
                    />
                }
                {(row != null && row.creditDetails.length == 0 && row.bankAccountDetails.length == 0) &&
                    <div className='dropdown-toggle-a'
                        onClick={(e) => {
                          
                        }}>Upload Documents</div>
                } */}
                <div className='dropdown-toggle-a'
                    onClick={(e) => {

                    }}>Send AI Copilot</div>
            </div>
        </div>
    );
    //// columns for data tabels
    const columns = [
        {
            style: {
                display: 'flex',
                justifyContent: 'start',
            },
            sortable: false,
            cell: row => <UserName row={row} />,
        },
        {
            sortable: false,
            style: {
                display: 'flex',
                justifyContent: 'end',
            },
            cell: row => <DownloadUploadButton row={row} />,

        },
        {
            sortable: false,
            width: '3rem',
            style: {
                display: 'flex',
                justifyContent: 'center',
            },
            cell: row => <div data-tag="allowRowEvents">
                <img src={DownArrowIC} alt='UserListIC' className={row === currentRow ? 'down-arrow-ic custom-rotate-180' : 'down-arrow-ic'}
                    data-tag="allowRowEvents" />
            </div>,
        },

    ];


    const opneSDKProdUser = async () => {
        generateClientTokenProdB2B(true);
    };

    const generateClientTokenProdB2B = async (isOpenSdk = false) => {
        getCommonProdClientTokenB2B(
            async () => {
                if (isOpenSdk) {
                    const overrideSdkOnboardingOtpByPass = '&overrideSdkOnboardingOtpByPass=true';
                    const prodBaseURl = config.prodApiUrl;
                    const token = await AsyncStorage.getItem('b2bProdToken');
                    const businessUserId = uuidv4(); ///generate everytime new uuidv4 for businessUserId
                    const startFlow = 'CREDIT';
                    const redirectUrl = 'https://enterprise.boost.money/prod-users';
                    const sdkUrl = `${prodBaseURl}sdk/onboard?token=${token}&businessUserId=${businessUserId}&startFlow=${startFlow}&redirectUrl=${redirectUrl}${overrideSdkOnboardingOtpByPass}`;
                    window.open(sdkUrl, '_blank', 'noreferrer');
                } else {
                    setIsOpenTestUserDialog(true);
                }
            },
            (errorMessage) => {
                console.error('Error:', errorMessage); // Handle error
            }
        );
    }

    const searchController = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        if (filterText === '') {
            setUserListData(userListDataFilter);
        } else {
            const filteredItems = userListDataFilter.filter(item =>
                (item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase()))
            );
            setUserListData(filteredItems);
        }

        return (
            <div className='p-0 m-0 d-flex flex-row px-1 py-2 justify-content-end align-items-end'>
                <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </div>
        );
    }, [filterText, resetPaginationToggle]);


    /// expandalbe desgin inside of each cells
    const ExpandedComponent = (props) => {

        return (
            <div className='m-0 p-0'>
                <div className='col-12 d-flex flex-wrap info-root-box p-3'>
                    <p className='info-title m-0 p-0 ps-2 pt-2 pb-1'>Summary</p>
                    <div className='col-12 m-0 p-0 '>
                        <div className='row p-0 m-0'>
                            {props.data != null ? (<div className='col-lg-12 col-md-12 ps-1 m-0 pt-3 pt-lg-3 pt-md-3 pt-sm-3'
                                key={props.userId}>
                                <div className='info-details-box row p-0 m-0 px-lg-2 px-md-2 '>

                                    <div className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12'>
                                        <div className='d-flex col-lg-12 flex-column m-0'>
                                            <div className='d-flex justify-content-between pt-3 pb-2'>
                                                <p className='info-details-main-text m-0 '>LOS Application ID</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.applicationId ?? 'N/A'}</p>
                                            </div>
                                            <div className='dividers p-0 m-0'></div>
                                            <div className='d-flex justify-content-between pt-2 pb-1'>
                                                <p className='info-details-main-text m-0'>Phone Number</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.mobileNumber ?? 'N/A'}</p>
                                            </div>
                                            <div className='dividers p-0 m-0'></div>
                                            <div className='d-flex justify-content-between pt-2 pb-2'>
                                                <p className='info-details-main-text m-0'>Gender</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.gender ?? 'N/A'}</p>
                                            </div>
                                            <div className='dividers p-0 m-0'></div>
                                            <div className='d-flex justify-content-between pt-2 pb-3 '>
                                                <p className='info-details-main-text m-0'>PAN</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.pan ?? 'N/A'}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='vertical-divider d-none d-lg-inline d-md-inline'></div>
                                    <div className='dividers p-0 m-0 d-inline d-lg-none d-md-none'></div>
                                    <div className='row p-0 m-0 col-12 col-lg-6 col-md-6 col-sm-12 '>

                                        <div className='d-flex col-lg-12 flex-column m-0'>
                                            <div className='d-flex justify-content-between pt-3 pb-2'>
                                                <p className='info-details-main-text m-0'>Credit Report ID</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.creditReportId ?? 'N/A'}</p>
                                            </div>
                                            <div className='dividers p-0 m-0'></div>
                                            <div className='d-flex justify-content-between pt-2 pb-1'>
                                                <p className='info-details-main-text m-0'>Active Credit Accounts</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.activeAccount ?? 'N/A'}</p>
                                            </div>
                                            <div className='dividers p-0 m-0'></div>
                                            <div className='d-flex justify-content-between pt-2 pb-2'>
                                                <p className='info-details-main-text m-0'>Overdue Credit Accounts</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.overdueAcc ?? 'N/A'}</p>
                                            </div>
                                            <div className='dividers p-0 m-0'></div>
                                            <div className='d-flex justify-content-between  pt-2 pb-3'>
                                                <p className='info-details-main-text m-0'>New accounts in past 6 months</p>
                                                <p className='info-details-sub-text m-0 bold-sub-text'>{props.data.overdueAcc ?? 'N/A'}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>) : <></>}


                        </div>
                    </div>

                    {/*Credit information - END*/}
                    <div className='dividers w-100 mt-4 mb-3'></div>
                    {/*Bank statement information - START*/}
                    <p className='info-title m-0 p-0 ps-2 pt-2 pb-3'>Cross Sell Score </p>
                    <div className='col-12 m-0 p-0'>
                        <div className='row info-details-box p-0 m-0 row p-0 m-0 px-lg-2 px-md-2'>
                            {props.data.crossSellScoreTypesList &&
                                props.data.crossSellScoreTypesList.map((creditDetail, index) => (
                                    <div className={`col-lg-6 col-md-6 col-sm-12 p-0 m-0 ${index < props.data.crossSellScoreTypesList.length - 2
                                        ? 'border-bottom' : ''}`} key={index}>
                                        <div className='info-box p-0 m-0 pt-0 px-2'>
                                            <div className='d-flex flex-column p-0 m-0'>
                                                <div className='d-flex justify-content-between pt-3 pb-2'>
                                                    <p className='info-details-main-text m-0'>Score Type</p>
                                                    <p className='info-details-sub-text m-0 bold-sub-text'>
                                                        {creditDetail.crossSaleScoreName ?? 'N/A'}
                                                    </p>
                                                </div>
                                                <div className='d-flex justify-content-between pt-2 pb-2'>
                                                    <p className='info-details-main-text m-0'>Score Value </p>
                                                    <p className='info-details-sub-text m-0 response-status'>
                                                        {creditDetail.crossSellScoreValue ?? 'N/A'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className='col-12 d-flex flex-row align-items-center pt-4 justify-content-center
                     cursor-pointer'>
                        <p className='p-0 m-0 info-upload-label'
                            onClick={(e) => {
                                e.preventDefault();
                                let data = {
                                    'businessUserId': props.data.businessUserId,
                                    'isOpnePanel': true,
                                    'fullName': props.data.firstName + " " + props.data.lastName,
                                    'date': props.data.createdDate != null ? moment(props.data.createdDate).format('DD-MM-YYYY h:mm A') : '-',
                                };
                                console.log("props.data.businessUserId ", data)
                                setPanelData(data);

                            }}>View Details</p>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <>
            {loader && <Loader />}
            <div id="sdk" className='mt-4 mt-sm-2'>
                <section className='' id='uatenv'>
                    <div className='container-lg container-fluid mt-3 my-sm-3'>
                        <div className='row align-items-start g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0 '>
                            <div className='col-5'>
                                <div className='ps-sm-2'>
                                    <h1 className='username-text mb-2 mb-sm-3'>My Customers</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <section>
                <ErrorMessage message={message} />
            </section>



            <section id='customerlist'>
                <div className='container-lg container-fluid my-3'>
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                <div className='w-100'>
                                    <div>
                                        <div className='d-flex justify-content-between'>
                                            <p className="acc-info-text" >Customers List</p>
                                        </div>
                                    </div>
                                </div>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                <div className="accordion-body p-0 m-0">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='text-end my-3'>

                                            </div>
                                            <div className='account-accordion-box'>
                                                <div className="accordion" id="accordionFlushExample">

                                                    {userListData && <DataTable
                                                        title="Users"
                                                        columns={columns}
                                                        data={userListData}
                                                        noHeader={true}
                                                        customStyles={customStyles}
                                                        noTableHead={true}
                                                        subHeader
                                                        subHeaderComponent={searchController}
                                                        expandableRows
                                                        expandOnRowClicked={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                        pagination
                                                        paginationComponent={PaginationComponent}
                                                        paginationServer={true}
                                                        paginationPerPage={sizePerPage}
                                                        paginationTotalRows={totalRecordFromApi}
                                                        onChangePage={handlePerRowsChange}
                                                        onRowExpandToggled={(bool, row) => {
                                                            if (row === currentRow) {
                                                                setCurrentRow(null); // Collapse the row if it's already expanded
                                                            } else {
                                                                setCurrentRow(row); // Expand the row if it's collapsed
                                                            }
                                                        }}
                                                        expandableRowExpanded={(row) => (row === currentRow)}
                                                        onRowClicked={(row) => setCurrentRow(row)}
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            <section>
                <SlidingPanel
                    width={window.innerWidth <= 768 ? "90%" : "80%"}
                    from='right'
                    hideHeader={false}
                    isOpen={panelData.isOpnePanel}
                    title={panelData.fullName}
                    subtitle={panelData.date}
                    onRequestClose={() => {
                        // triggered on "<" on left top click or on outside click

                        setPanelData(prevState => ({
                            ...prevState,
                            'isOpnePanel': false
                        }));
                    }}>

                    <section>
                        <div className='p-0 m-0 flex-column'>
                            <TabComponent index={1} ></TabComponent>
                            <div className='mt-2'>
                                <SubTabComponent businessUserId={panelData.businessUserId}></SubTabComponent>
                            </div>
                        </div>
                    </section>
                </SlidingPanel>
            </section>

        </>
    );
}

export default MyCustomerList;